import { StyleParamType, wixColorParam, wixFontParam, createStylesParams } from '@wix/tpa-settings';
import {
  LAYOUT_TYPE_MAIN_PAGE,
  IS_SHOW_SUBCATEGORIES_ENABLED,
  IS_HEADER_ENABLED,
  MAIN_PAGE_HEADER_HEIGHT,
  MAIN_PAGE_TEXT_ALIGNMENT,
  CARD_SIZE,
  IS_MAIN_PAGE_HEADER_ENABLED,
  HEADER_HEIGHT,
  CATEGORY_TEXT_ALIGNMENT,
  LAYOUT_TYPE,
  FORUM_STRUCTURE,
  POST_SORTING,
  IS_VIEWS_COUNT_ENABLED,
  IS_LIST_HASHTAGS_ENABLED,
  IS_SOCIAL_SHARING_LINKS_ENABLED,
  IS_PINTEREST_PIN_ENABLED,
  IS_BADGES_ENABLED,
  IS_LOGIN_WIDGET_ENABLED,
  IS_RCE_IMAGE_ENABLED,
  IS_RCE_VIDEO_ENABLED,
  IS_RCE_DIVIDER_ENABLED,
  IS_RCE_HTML_ENABLED,
  IS_RCE_GIPHY_ENABLED,
  IS_RCE_FILE_UPLOAD_ENABLED,
  IS_RCE_EMOJI_ENABLED,
  IS_RCE_EVENTS_EMBED_ENABLED,
  IS_RCE_BOOKINGS_EMBED_ENABLED,
  IS_RCE_STORES_EMBED_ENABLED,
  IS_RCE_COLLAPSIBLE_LIST_ENABLED,
  IS_RCE_POLLS_ENABLED,
  IS_RCE_CODE_BLOCK_ENABLED,
  IS_FOOTER_POSTS_ENABLED,
  IS_SIMILAR_POSTS_ENABLED,
  IS_AUTO_SUGGESTIONS_ENABLED,
  HEADER_TEXT_COLOR,
  HEADER_DIVIDER_COLOR,
  APP_TEXT_COLOR,
  BUTTON_TEXT_COLOR,
  BUTTON_COLOR,
  BUTTON_BORDER_RADIUS,
  LINKS_HASHTAGS_COLOR,
  SECONDARY_ICON_COLOR,
  POST_BORDER_RADIUS,
  APP_BACKGROUND_COLOR,
  CARD_BACKGROUND_COLOR,
  IS_COMMENTS_IN_FEED_ENABLED,
  POSTS_FEED_COMMENTS_COUNT,
} from '@wix/communities-forum-client-commons';
import { LayoutTypes } from './Settings/constants/layout-types';
import { TextAlignment } from './Settings/constants/text-alignment';
import { CardSizes } from './Settings/constants/card-sizes';
import { Structure } from './Settings/constants/structure';
import { PostSortingSettings } from './Settings/constants/post-sorting';
import { DefaultFontSizes } from './Settings/constants/default-font-sizes';

export type IStylesParams = {
  mainPageLayout: StyleParamType.Number;
  isShowSubcategoriesEnabled: StyleParamType.Boolean;
  isMainPageHeaderEnabled: StyleParamType.Boolean;
  mainPageHeaderHeight: StyleParamType.Number;
  mainPageHeaderTextAlignment: StyleParamType.Number;
  postsPageLayout: StyleParamType.Number;
  postsPageCardSize: StyleParamType.Number;
  isPostPageHeaderEnabled: StyleParamType.Boolean;
  postsPageHeaderHeight: StyleParamType.Number;
  postsPageHeaderTextAlignment: StyleParamType.Number;
  isFeedCommentsEnabled: StyleParamType.Boolean;
  postsFeedCommentsCount: StyleParamType.Number;
  mainPageStructure: StyleParamType.Number;
  postSorting: StyleParamType.Number;
  isViewCounterEnabled: StyleParamType.Boolean;
  isHashtagsEnabled: StyleParamType.Boolean;
  isSocialSharingIconsEnabled: StyleParamType.Boolean;
  isPinterestPinEnabled: StyleParamType.Boolean;
  isBadgesEnabled: StyleParamType.Boolean;
  isLoginWidgetEnabled: StyleParamType.Boolean | StyleParamType.Number;
  isRceImageEnabled: StyleParamType.Boolean;
  isRceVideoEnabled: StyleParamType.Boolean;
  isRceDividerEnabled: StyleParamType.Boolean;
  isRceHtmlEnabled: StyleParamType.Boolean;
  isRceCodeBlockEnabled: StyleParamType.Boolean;
  isRceGiphyEnabled: StyleParamType.Boolean;
  isRceFileUploadEnabled: StyleParamType.Boolean;
  isRceEmojiEnabled: StyleParamType.Boolean;
  isRceEventsEmbedEnabled: StyleParamType.Boolean;
  isRceBookingsEmbedEnabled: StyleParamType.Boolean;
  isRceStoresEmbedEnabled: StyleParamType.Boolean;
  isRceCollapsibleListEnabled: StyleParamType.Boolean;
  isRcePollsEnabled: StyleParamType.Boolean;
  isFooterPostsEnabled: StyleParamType.Boolean;
  isSimilarPostsEnabled: StyleParamType.Boolean;
  isAutoSuggestionsEnabled: StyleParamType.Boolean;
  headerTextColor: StyleParamType.Color;
  backgroundColor: StyleParamType.Color;
  headerDividerColor: StyleParamType.Color;
  navigationItemFont: StyleParamType.Font;
  headerTitleFont: StyleParamType.Font;
  headerDescriptionFont: StyleParamType.Font;
  appTextColor: StyleParamType.Color;
  postTitleFontLarge: StyleParamType.Font;
  postTitleFontCompact: StyleParamType.Font;
  postTitleFontMedium: StyleParamType.Font;
  postTitleFontSmall: StyleParamType.Font;
  postTitleFontClassic: StyleParamType.Font;
  postTitleFontFeed: StyleParamType.Font;
  postContentFontLarge: StyleParamType.Font;
  postContentFontCompact: StyleParamType.Font;
  postContentFontMedium: StyleParamType.Font;
  postContentFontSmall: StyleParamType.Font;
  postContentFontClassic: StyleParamType.Font;
  postContentFontFeed: StyleParamType.Font;
  buttonTextColor: StyleParamType.Color;
  buttonColor: StyleParamType.Color;
  buttonBorderRadius: StyleParamType.Number;
  linksHashtagsColor: StyleParamType.Color;
  secondaryIconColor: StyleParamType.Color;
  appBackgroundColor: StyleParamType.Color;
  cardBackgroundColor: StyleParamType.Color;
  cardBorderColor: StyleParamType.Color;
  postBorderWidthClassic: StyleParamType.Number;
  postBorderWidthCompact: StyleParamType.Number;
  postBorderWidthLarge: StyleParamType.Number;
  postBorderWidthMedium: StyleParamType.Number;
  postBorderWidthSmall: StyleParamType.Number;
  postBorderRadius: StyleParamType.Number;
};

interface OverrideOptions {
  isMobile: boolean;
}

const dangerousKeyTransformationOverride = (originalKey: string, { isMobile }: OverrideOptions) => {
  return `${originalKey}${isMobile ? '-mobile' : ''}`;
};

export default createStylesParams<IStylesParams>({
  mainPageLayout: {
    key: LAYOUT_TYPE_MAIN_PAGE,
    type: StyleParamType.Number,
    getDefaultValue: () => LayoutTypes.CLASSIC,
    dangerousKeyTransformationOverride,
  },
  isShowSubcategoriesEnabled: {
    key: IS_SHOW_SUBCATEGORIES_ENABLED,
    type: StyleParamType.Boolean,
    inheritDesktop: true,
    getDefaultValue: () => false,
    dangerousKeyTransformationOverride,
  },
  isMainPageHeaderEnabled: {
    key: IS_MAIN_PAGE_HEADER_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    inheritDesktop: true,
    dangerousKeyTransformationOverride,
  },
  mainPageHeaderHeight: {
    key: MAIN_PAGE_HEADER_HEIGHT,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 100 : 250),
    dangerousKeyTransformationOverride,
  },
  mainPageHeaderTextAlignment: {
    key: MAIN_PAGE_TEXT_ALIGNMENT,
    type: StyleParamType.Number,
    getDefaultValue: () => TextAlignment.CENTER,
    dangerousKeyTransformationOverride,
  },
  postsPageLayout: {
    key: LAYOUT_TYPE,
    type: StyleParamType.Number,
    getDefaultValue: () => LayoutTypes.CLASSIC,
    dangerousKeyTransformationOverride,
  },
  postsPageCardSize: {
    key: CARD_SIZE,
    type: StyleParamType.Number,
    getDefaultValue: () => CardSizes.MEDIUM,
    dangerousKeyTransformationOverride,
  },
  isPostPageHeaderEnabled: {
    key: IS_HEADER_ENABLED,
    type: StyleParamType.Boolean,
    inheritDesktop: true,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  postsPageHeaderHeight: {
    key: HEADER_HEIGHT,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 100 : 250),
    dangerousKeyTransformationOverride,
  },
  postsPageHeaderTextAlignment: {
    key: CATEGORY_TEXT_ALIGNMENT,
    type: StyleParamType.Number,
    getDefaultValue: () => TextAlignment.CENTER,
    dangerousKeyTransformationOverride,
  },
  isFeedCommentsEnabled: {
    key: IS_COMMENTS_IN_FEED_ENABLED,
    type: StyleParamType.Boolean,
    inheritDesktop: true,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  postsFeedCommentsCount: {
    key: POSTS_FEED_COMMENTS_COUNT,
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
    dangerousKeyTransformationOverride,
  },
  mainPageStructure: {
    key: FORUM_STRUCTURE,
    type: StyleParamType.Number,
    getDefaultValue: () => Structure.REGULAR,
    dangerousKeyTransformationOverride,
  },
  postSorting: {
    key: POST_SORTING,
    type: StyleParamType.Number,
    getDefaultValue: () => Number(PostSortingSettings.SORT_BY_LAST_ACTIVITY),
    dangerousKeyTransformationOverride,
  },
  isViewCounterEnabled: {
    key: IS_VIEWS_COUNT_ENABLED,
    type: StyleParamType.Boolean,
    inheritDesktop: true,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isHashtagsEnabled: {
    key: IS_LIST_HASHTAGS_ENABLED,
    type: StyleParamType.Boolean,
    inheritDesktop: true,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isSocialSharingIconsEnabled: {
    key: IS_SOCIAL_SHARING_LINKS_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isPinterestPinEnabled: {
    key: IS_PINTEREST_PIN_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
    dangerousKeyTransformationOverride,
  },
  isBadgesEnabled: {
    key: IS_BADGES_ENABLED,
    type: StyleParamType.Boolean,
    inheritDesktop: true,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isLoginWidgetEnabled: {
    key: IS_LOGIN_WIDGET_ENABLED,
    type: StyleParamType.Boolean || StyleParamType.Number,
    inheritDesktop: true,
    getDefaultValue: () => 0,
    dangerousKeyTransformationOverride,
  },
  isRceImageEnabled: {
    key: IS_RCE_IMAGE_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRceVideoEnabled: {
    key: IS_RCE_VIDEO_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRceDividerEnabled: {
    key: IS_RCE_DIVIDER_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRceHtmlEnabled: {
    key: IS_RCE_HTML_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
    dangerousKeyTransformationOverride,
  },
  isRceCodeBlockEnabled: {
    key: IS_RCE_CODE_BLOCK_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRceGiphyEnabled: {
    key: IS_RCE_GIPHY_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRceFileUploadEnabled: {
    key: IS_RCE_FILE_UPLOAD_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRceEmojiEnabled: {
    key: IS_RCE_EMOJI_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRceEventsEmbedEnabled: {
    key: IS_RCE_EVENTS_EMBED_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRceBookingsEmbedEnabled: {
    key: IS_RCE_BOOKINGS_EMBED_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRceStoresEmbedEnabled: {
    key: IS_RCE_STORES_EMBED_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRceCollapsibleListEnabled: {
    key: IS_RCE_COLLAPSIBLE_LIST_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isRcePollsEnabled: {
    key: IS_RCE_POLLS_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isFooterPostsEnabled: {
    key: IS_FOOTER_POSTS_ENABLED,
    type: StyleParamType.Boolean,
    inheritDesktop: true,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isSimilarPostsEnabled: {
    key: IS_SIMILAR_POSTS_ENABLED,
    type: StyleParamType.Boolean,
    inheritDesktop: true,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  isAutoSuggestionsEnabled: {
    key: IS_AUTO_SUGGESTIONS_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
    dangerousKeyTransformationOverride,
  },
  headerTextColor: {
    key: HEADER_TEXT_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
    dangerousKeyTransformationOverride,
  },
  backgroundColor: {
    key: 'header-backgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0.5),
    dangerousKeyTransformationOverride,
  },
  headerDividerColor: {
    key: HEADER_DIVIDER_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
    dangerousKeyTransformationOverride,
  },
  navigationItemFont: {
    key: 'navigation-itemFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.NAVIGATION,
    }),
    dangerousKeyTransformationOverride,
  },
  headerTitleFont: {
    key: 'header-titleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-S', {
      size: DefaultFontSizes.HEADER_TITLE,
    }),
    dangerousKeyTransformationOverride,
  },
  headerDescriptionFont: {
    key: 'header-descriptionFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.HEADER_DESCRIPTION,
    }),
    dangerousKeyTransformationOverride,
  },
  appTextColor: {
    key: APP_TEXT_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
    dangerousKeyTransformationOverride,
  },
  postTitleFontLarge: {
    key: 'post-titleFontLarge',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_LARGE_TITLE,
    }),
    dangerousKeyTransformationOverride,
  },
  postTitleFontCompact: {
    key: 'post-titleFontCompact',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_SMALL_TITLE,
    }),
    dangerousKeyTransformationOverride,
  },
  postTitleFontMedium: {
    key: 'post-titleFontMedium',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_TITLE,
    }),
    dangerousKeyTransformationOverride,
  },
  postTitleFontSmall: {
    key: 'post-titleFontSmall',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_TITLE,
    }),
    dangerousKeyTransformationOverride,
  },
  postTitleFontClassic: {
    key: 'post-titleFontClassic',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_TITLE,
    }),
    dangerousKeyTransformationOverride,
  },
  postTitleFontFeed: {
    key: 'post-titleFontFeed',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_LARGE_TITLE,
    }),
    dangerousKeyTransformationOverride,
  },
  postContentFontLarge: {
    key: 'post-contentFontLarge',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_LARGE_CONTENT,
    }),
    dangerousKeyTransformationOverride,
  },
  postContentFontCompact: {
    key: 'post-contentFontCompact',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_SMALL_CONTENT,
    }),
    dangerousKeyTransformationOverride,
  },
  postContentFontMedium: {
    key: 'post-contentFontMedium',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_CONTENT,
    }),
    dangerousKeyTransformationOverride,
  },
  postContentFontSmall: {
    key: 'post-contentFontSmall',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_CONTENT,
    }),
    dangerousKeyTransformationOverride,
  },
  postContentFontClassic: {
    key: 'post-contentFontClassic',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_CONTENT,
    }),
    dangerousKeyTransformationOverride,
  },
  postContentFontFeed: {
    key: 'post-contentFontFeed',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: DefaultFontSizes.POST_CONTENT,
    }),
    dangerousKeyTransformationOverride,
  },
  buttonTextColor: {
    key: BUTTON_TEXT_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
    dangerousKeyTransformationOverride,
  },
  buttonColor: {
    key: BUTTON_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
    dangerousKeyTransformationOverride,
  },
  buttonBorderRadius: {
    key: BUTTON_BORDER_RADIUS,
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
    dangerousKeyTransformationOverride,
  },
  linksHashtagsColor: {
    key: LINKS_HASHTAGS_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
    dangerousKeyTransformationOverride,
  },
  secondaryIconColor: {
    key: SECONDARY_ICON_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-13'),
    dangerousKeyTransformationOverride,
  },
  appBackgroundColor: {
    key: APP_BACKGROUND_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
    dangerousKeyTransformationOverride,
  },
  cardBackgroundColor: {
    key: CARD_BACKGROUND_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
    dangerousKeyTransformationOverride,
  },
  cardBorderColor: {
    key: 'post-cardBorderColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
    dangerousKeyTransformationOverride,
  },
  postBorderWidthClassic: {
    key: 'post-borderWidthClassic',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
    dangerousKeyTransformationOverride,
  },
  postBorderWidthCompact: {
    key: 'post-borderWidthCompact',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
    dangerousKeyTransformationOverride,
  },
  postBorderWidthLarge: {
    key: 'post-borderWidthLarge',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
    dangerousKeyTransformationOverride,
  },
  postBorderWidthMedium: {
    key: 'post-borderWidthMedium',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
    dangerousKeyTransformationOverride,
  },
  postBorderWidthSmall: {
    key: 'post-borderWidthSmall',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
    dangerousKeyTransformationOverride,
  },
  postBorderRadius: {
    key: POST_BORDER_RADIUS,
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
    dangerousKeyTransformationOverride,
  },
});
